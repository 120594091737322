export type SignupSource = "inspires" | "collection-invite" | "brand-experiences" | "brand-copilot" | "brand-generic";

const useSignup = () => {
  const sources: SignupSource[] = ["inspires", "collection-invite", "brand-experiences", "brand-copilot", "brand-generic"];

  const isValidSource = (source: SignupSource) => sources.includes(source);

  return {
    sources,
    isValidSource,
  };
};

export default useSignup;
